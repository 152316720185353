<template>
  <div class="shadow bg-white">
      <va-tabs>
        <div slot="content" id="wallet" data-title="Wallet" class="active">
          <div class="text-right">
            <button class="btn btn-primary" @click="generateXML('wallet')" :disabled="isBtnLoading">
              <span>Generate XML</span>
              <span v-if="isBtnLoading">
                <img class="w-20 h-20 ml-8" src="/assets/img/tail-spin.svg" alt=""/>
              </span>
            </button>
          </div>
        </div>
        <div slot="content" id="exchange" data-title="Exchange">
          <div class="text-right">
            <button class="btn btn-primary" @click="generateXML('exchange')" :disabled="isBtnLoading">
              <span>Generate XML</span>
              <span v-if="isBtnLoading">
                <img class="w-20 h-20 ml-8" src="/assets/img/tail-spin.svg" alt=""/>
              </span>
            </button>
          </div>
        </div>
      </va-tabs>
  </div>
</template>

<script>
import VaTabs from "@/components/VATabs.vue";
import { mapGetters } from "vuex";
import { START_BTN_LOADING, STOP_BTN_LOADING } from "@/store/keys";
import { saveNotification } from "../lib/utils";

export default {
  name: "Reports",
  components: {
    VaTabs,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "isBtnLoading",
      "jwtToken",
    ]),
  },
  methods: {
    generateXML(type) {
      this.$store.commit(START_BTN_LOADING);
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=GenerateXML`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          type,
        },
        responseType: "blob",
      }).then(response => {
        this.$store.commit(STOP_BTN_LOADING);
        if (response.data.size === 57) {
          const self = this;
          const reader = new FileReader();
          reader.onload = function () {
            const data = JSON.parse(reader.result);
            self.$toast.fire("", data.message, "info");
            saveNotification(data.message);
          };
          reader.readAsText(new Blob([response.data]));
        } else {
          const fileName = this.tz().format("YYYYMMDD") + ".zip";
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const element = document.createElement("a");
          element.setAttribute("href", url);
          element.setAttribute("download", fileName);

          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }
      }).catch(error => {
        this.$store.commit(STOP_BTN_LOADING);
        console.log(error);
      });
    },
  },
  mounted() {
  },
  beforeDestroy() {
    this.$store.commit(STOP_BTN_LOADING);
  },
};
</script>

<style scoped></style>
